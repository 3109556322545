//footer

footer
{
    p{margin:0 0 10px; }
}

.id-selector {
    height: 100vh;
    position: fixed;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    right: 0;
    top: 67px;
    z-index: 200;
    .modal-scroll {
	    overflow: auto;
	    position: relative;
	    min-width: 240px;
	    height: calc(100vh - 167px) !important;
	}
}
.team-work {
        height: calc(100vh - 119px) !important;
        position: relative;
    .covar-img {
      background-position: center -11px;
      background-attachment: fixed;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
    }
    
    .overlay {
      background-color: #1c1a22;
      position: absolute;
      height: 100%;
      width: 100%;
    }
}
.login-landing {
    .login-form {
      min-height: initial;
        .page__stat {
          padding: 29px 32px 31px;
        }  
    }
}  

/* --- Pricing Plans --- */

.plans-packages {   
  .pricing-plans {
    width: 100%; 
    .home-heading {
        h3 {
            font-size: 32px;
            font-weight: 600;
            text-transform: uppercase;
            color: #1c1a22;
        }
        p {
           color: #4e4e50;
           font-weight: 500;
        }
    } 
  }

  .pricing-tables {
    display: flex;
    flex-flow: column;
    padding-top: 1em;
  }

  .pricing-plan {
      background-color: #fff;
      border: 2px solid #DDD;
      border-bottom: 2px solid #DDD;
      display: block;
      padding: 1em 0;
      text-align: center;
      width: 100%;
      box-shadow: 0px 6px 12px -8px;
      border-radius: 21px;
      margin-bottom: 27px;
      height: 100%;
      position: relative;
      overflow: hidden;
      &:before {
        content: "";
        width: 46px;
        height: 46px;
        background: #cbc6ef;
        position: absolute;
        right: -11px;
        border-radius: 50px;
        top: -8px;
      }
  }

  .pricing-plan:first-child {
    border-bottom: 0;
  }

  .pricing-plan:last-child {
    border-top: 0;
  }

  .pricing-plan:nth-child(2) {
    border-bottom: 0;
  }

  .no-flexbox .pricing-plan {
    float: left;
  }

  .plan-title {
    font-size: 1em;
    letter-spacing: -0.05em;
    margin: 0;
    padding: 0.75em 1em 0.5em;
    text-transform: uppercase;
  }

  .plan-cost {
    background-color: white;
    color: #7668d6;
    font-size: 1.25em;
    font-weight: 700;
    padding: 1.25em 1em;
    text-transform: uppercase;
  }

  .plan-cost span {
    display: none;
  }

  .plan-price {
    font-size: 3em;
    letter-spacing: -0.05em;
    line-height: 1;
    margin-bottom: 0;
  }

  .plan-type {
    border: 0.313em solid #DDD;
    color: #999;
    display: inline-block;
    font-size: 0.75em;
    margin: 0.75em 0 0 0.75em;
    padding: 0.3em 0.4em 0.25em;
    width: auto;
  }

  .plan-features {
    margin: 0;
    padding: 2em 1em 1em;
    min-height: 145px;
  }

  .plan-features {
    li {
        list-style-type: none;
        border-bottom: 1px dotted #DDD;
        margin-bottom: 0.5em;
        padding-bottom: 0.75em;
        color: #555;
        display: block;
        font-size: .8em;
        font-weight: 600;
        line-height: 1.3;
        span { color: #BBB;
      }
      i {
        font-size: 1.5em;
      }
      i.icon-ok-squared {
        color: #3aa14d;
      }
      i.icon-cancel-circled {
        color: darkRed;
      }
    }
  } 
  .plan-features {
    li {
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }


  .btn-plan {
    background-color: #1B8DC8;
    color: white;
    max-width: 12em;
  }

  .cta {
    background-color: #6cb507;
  }

  .featured-plan {
    background-color: #eef7fc;
    border-top: 5px solid #8cd0f5;
    border-right: 0 solid transparent;
    border-bottom: 5px solid #8cd0f5;
    border-left: 0 solid transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    //order: -1;
    position: relative;
    transition: transform 400ms ease;
  }

  .featured-plan {
    .plan-title {
      color: #1B8DC8;
    }
  }

  .featured-ribbon {
    width: 200px;
    background: #1B8DC8;
    position: absolute;
    top: 15px;
    left: -60px;
    text-align: center;
    line-height: 35px;
    letter-spacing: 0.01em;
    font-size: 0.65em;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    /* Custom styles */
    /* Different positions */
  }

  .featured-ribbon.sticky {
    position: fixed;
  }

  .featured-ribbon.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  }

  .featured-ribbon.top-left {
    top: 25px;
    left: -50px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .featured-ribbon.top-right {
    top: 25px;
    right: -50px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .featured-ribbon.bottom-left {
    top: auto;
    bottom: 25px;
    left: -50px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .featured-ribbon.bottom-right {
    top: auto;
    right: -50px;
    bottom: 25px;
    left: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  @media (min-width: 400px) {
    .pricing-plans {    
      width: 100%;
    }

    .featured-plan {
      transform: scale(1.05);
    }
  }

  @media (min-width: 33.75em) {
    .pricing-plans .module-title {
      margin-bottom: 1em;
    }

    .pricing-tables {
      flex-flow: row wrap;
    }

    .pricing-plan {
      flex-grow: 1;
      width: 100%;
    }

    .pricing-plan:first-child {
      border-right: 0;
      border-bottom: 0;
    }
    
    .featured-plan {
      margin-top: 0.6em;
      order: 0;
    }
    
    .no-flexbox .pricing-plan {
      width: 48%;
    }

    .plan-title {
      font-size: 20px;
      font-weight: 600;
    }
  }

  @media (min-width: 48em) {
    .no-flexbox .pricing-plan {
      width: 24%;
    }

    .plan-type {
      font-size: 0.7em;
      margin: 0.5em 0 0 1em;
      padding-bottom: 0.2em;
    }

    .featured-ribbon {
      font-size: 0.65em;
    }
  }

  @media (min-width: 62em) {
    
    .pricing-plan {
      flex-grow: 1;
      width: 100%;
    }
    
    .featured-plan {
      margin-top: 0;
      order: 0;
    }

    .pricing-plan:first-child, .pricing-plan:nth-child(2n) {
      border-bottom: 9px solid #DDD;
    }

    .pricing-plan .plan-features span {
      display: block !important;
    }

    .plan-cost {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      font-size: 1em;
    }

    .plan-cost span {
      color: #BBB;
      font-size: 1.5em;
      font-weight: 400;
      padding-right: 0.15em;
      padding-left: 0.15em;
    }

    .plan-price {
      font-size: 3.25em;
    }

    .btn-plan {
      font-size: 0.875em;
    }

    .featured-ribbon {
      font-size: 0.45em;
      left: -68px;
      line-height: 25px;
    }
  }

  @media (min-width: 75em) {
    .plan-cost {
      font-size: 1em;
    }
  }

  @media (min-width: 100em) {
    .pricing-tables {
      margin: 0 auto;
      max-width: 75.00em;
    }
  }
}  

footer {
  background-color: #7668d6;
  text-align: center;
    .socal-midia {
        .nav-link {
          color: #fff;
          font-size: 18px;
        }  
    }
}
.sorting {
    margin-bottom: 15px;
}

table {
    > :not(:last-child)> :last-child>* {
        border-bottom-color: #e1e1e1 !important;
    }

    thead {
        th {
            font-size: 13px;
            color: #B2B3BD;
            font-weight: 500;
            padding: 24px 5px 24px 5px !important;
            position: relative;

            &.sort-by {
                padding-right: 18px !important;
                position: relative;
                cursor: pointer;

                &:before {
                    border: 4px solid transparent;
                    content: "";
                    display: block;
                    height: 0;
                    right: 5px;
                    top: 50%;
                    position: absolute;
                    width: 0;
                    border-bottom-color: #666;
                    margin-top: -9px;
                }

                &:after {
                    border: 4px solid transparent;
                    content: "";
                    display: block;
                    height: 0;
                    right: 5px;
                    top: 50%;
                    position: absolute;
                    width: 0;
                    border-top-color: #666;
                    margin-top: 1px;
                }
            }

            .sorting {
                position: absolute;
                right: 10px;
                top: 50%;
                height: 22px;
                margin-top: -13px;

                a {
                    display: block;
                    color: #666;
                    font-size: 12px;
                    opacity: .5;
                    height: 7px;
                    margin: 2px;
                    padding: 1px;

                    &.active,
                    &:hover {
                        opacity: 1;
                    }

                    i {
                        display: block;
                        height: 5px;

                        &:last-child i {
                            margin-top: -6px !important;
                        }
                    }

                    &:first-child {
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }

    tbody {
        th {
            padding: 32px 32px 32px 0px !important;
            vertical-align: middle;
            color: #a4a5a6;
            font-weight: 600;
            font-size: 14px;
        }

        td {
            vertical-align: middle;
            font-weight: 600;
            font-size: 14px;
            word-break: break-all;

            .btn-width {
                min-width: 92px;
            }

            .departuser_name {
                width: 72px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &:hover {
                    transform: scale(1.5);
                    z-index: 9999;
                    font-size: 9px;
                    transform-origin: top left;
                    box-shadow: $box-shadow-lg;
                    background-color: $white;
                    padding: 5px;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    & span {
                        display: flex;
                        margin-bottom: 4px;
                        font-size: 9px;
                        white-space: break-spaces;
                    }
                }
            }
        }
    }

    .btn {
        padding: 5px 8px;
        font-size: 12px;
        text-transform: capitalize;
    }
}




@media (max-width: 992px) { 
    .responsiveTable {
        width: 100%;
        margin: 0;
        padding: 0;
        border-collapse: collapse;
        border-spacing: 0;
        
        thead {
            display: none;
            background: #fafafa;
        }
        
        tr {
            border: 1px solid #ddd;
            border-bottom: 2px solid #ddd;
            padding: 5px;
            margin-bottom: 10px;
            display: block;
        }
        
        th,
        td {
            padding: 10px;
            text-align: left;
        }
        
        th {
            text-transform: uppercase;
            font-size: 11px;
        }
        
        td {
            display: block;
            text-align: right;
            font-size: 13px;
            border-bottom: 1px dotted #ddd;
            min-height: 46px;
            &:last-child {
            border-bottom: none;
            }
            .departuser_name {
                float: right;
            }
        }
        
        td:before {
            content: attr(data-label);
            float: left;
            text-transform: uppercase;
            font-weight: bold;
            margin-right: 12px;
        }
        }
    .trend_btn {
        background: #6c5dd3;
        color: #fff;
        margin-right: 7px;
        padding: 5px 11px;
        border-radius: 31px;
        margin-top: 8px;
    }   
}

@media (max-width: 1024px) { 
    .compass {
        position: fixed;
        bottom: -584px;
        left: 17% !important;
        right: 16% !important;
        z-index: 9;
    }
    .page {
        &:before {
            display: none;
        }
        .page__wrapper-conten {
           padding: 6px; 
           .page__stat {
                padding: 24px 16px 18px;
           }
        }
    }
}

.search-icon-mobile {
    position: fixed;
    background: #4f41b2;
    padding: 13px 16px 11px;
    bottom: 68px;
    z-index: 9;
    color: #fff;
    border-radius: 50px;
    right: 25px;
    display: none;
    .fa-search {
        font-size: 16px;
    }
    @media (max-width: 1024px) {
        display: block !important;
    }
}
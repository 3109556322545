.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 256px;
	height: 100vh;
	padding: 140px 0 72px;
	background: #ffffff;
	border-right: 1px solid #E4E4E4;
    .sidebar__top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 140px;
    }
    .sidebar__close {
        position: relative;
        top: -3px;
        display: none;
        margin-right: 25px;
        font-size: 0;
        .icon {
            font-size: 20px;
            fill: #11142D;
        }
    }
    .icon-close {
        width: 1em;
        height: 1em;
        fill: #1B1D21;
    }
    .sidebar__logo {
        font-size: 0;
        .sidebar__pic {
            width: 176px;
            width: 176px;
            width: 176px;
        }
        .sidebar__pic_white {
            display: none;
        }
    }
    .sidebar__burger {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        width: 96px;
        height: 96px;
        padding: 20px;
        border-bottom: 1px solid transparent;
        font-size: 0;
    }
    .sidebar__wrapper {
        // max-height: 100%;
        padding: 0 10px 30px;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        max-height: 485px;
        .sidebar__logo {
            display: none;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: 0;
            overflow: hidden;
            margin: 0 -20px;
        }
    }
    .sidebar__inner {
        width: 215px;
        overflow: hidden;
        -webkit-transition: width .25s;
        -o-transition: width .25s;
        transition: width .25s;
    }
    .sidebar__list {
        margin-bottom: 20px;
    }
    .sidebar__group {
        &:not(:last-child) {
            position: relative;
            margin-bottom: 40px;
            padding-bottom: 30px;
            &:before {
                content: "";
                position: absolute;
                left: 20px;
                right: 20px;
                bottom: 0;
                height: 1px;
                background: #F0F3F6;
            }
        }
    }
    .caption-sm {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.33333;
    }
    .sidebar__caption {
        margin-bottom: 16px;
        padding-left: 20px;
        color: #808191;
    }
    .sidebar__item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 56px;
        padding: 0 20px;
        border-radius: 12px;
        background: transparent;
        font-size: 14px;
        font-weight: 600;
        color: #808191;
        -webkit-transition: color .25s;
        -o-transition: color .25s;
        transition: color .25s;
        -webkit-transition: all .25s;
        -o-transition: all .25s;
        transition: all .25s;
        .sidebar__text {
            margin-right: auto;
        }
    }
    .sidebar__item.active {
        background: #6C5DD3;
        color: #ffffff;
        .icon {
            opacity: 1;
            fill: #ffffff;
        }
    }
    .sidebar__icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 16px;
        font-size: 0;
        .icon {
            font-size: 21px;
            fill: #1B1D21;
            opacity: 0.4;
            -webkit-transition: all .25s;
            -o-transition: all .25s;
            transition: all .25s;
        }
    }
    .sidebar__details {
        position: relative;
        // display: none;
        padding: 20px 10px 33px;
        margin-bottom: -15px;
        background: #F7F7F7;
        border-radius: 12px;
        -webkit-transition: padding .25s, background .25s;
        -o-transition: padding .25s, background .25s;
        transition: padding .25s, background .25s;
        
    }
    .sidebar__user {
        position: relative;
        z-index: 2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 20px;
        height: 62px;
        border-radius: 12px;
        color: #11142D;
        cursor: pointer;
        -webkit-transition: background .25s;
        -o-transition: background .25s;
        transition: background .25s;
        .sidebar__arrow {
            -ms-flex-negative: 0;
            flex-shrink: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 24px;
            height: 24px;
            font-size: 0;
        }
        .sidebar__ava {
            -ms-flex-negative: 0;
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            font-size: 0;
            .sidebar__pic {
                width: 100%;
                min-height: 100%;
                border-radius: 50%;
            }
        }
        .sidebar__desc {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 calc(100% - 64px);
            flex: 0 0 calc(100% - 64px);
            width: calc(100% - 64px);
            padding: 0 5px 0 16px;
            .sidebar__man {
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                line-height: 1.42857;
                font-weight: 600;
            }
        }
    }
    .sidebar__user:hover, .sidebar__user.active {
        background: #3F8CFF;
        color: #ffffff;
 
    }    
    &:before {
        content: "";
        position: absolute;
        top: 8px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 72px;
        height: 4px;
        border-radius: 2px;
        background: #E4E4E4;
    }
    .sidebar__link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 56px;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 600;
        color: #808191;
        -webkit-transition: all .25s;
        -o-transition: all .25s;
        transition: all .25s;
        .sidebar__icon {
            width: 50px;
            margin: 0;
            padding-right: 6px;
            text-align: center;
            -webkit-transition: all .25s;
            -o-transition: all .25s;
            transition: all .25s;
            .icon {
                font-size: 21px;
                fill: #1B1D21;
                opacity: 0.4;
                -webkit-transition: all .25s;
                -o-transition: all .25s;
                transition: all .25s;
            }
        }
    }
    .sidebar__user:hover .sidebar__status, .sidebar__user.active .sidebar__status {
        color: #ffffff;
    }
   
}
// 
// common.scss
//


html,body, html body{
  font-family: 'Inter', sans-serif ;
  font-family: 'Poppins', sans-serif ;
}

body, body *  {
    font-size: 14px;
    line-height: 1.71429;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    color: #11142D;
}

* {margin: 0; padding: 0; }
h4, .h4, h5, .h5 {
    margin-top: 10px;
    margin-bottom: 10px;
}
h4, .h4
{
    font-size: $h4-font-size !important;
}
h5, .h5
{
    font-size: $h5-font-size !important;
}
h6, .h6
{
    font-size: $h6-font-size !important;
}
a{
    text-decoration: none;
    color: #11142D;
    &:hover {
      text-decoration: none !important;
    }
}
input[type="number"] {
  -moz-appearance: none;
  appearance: none;
} 
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $white-70;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.modal.fade {
 opacity: initial;
}
.header {
  .navbar {
    padding: 14px 0px;
    box-shadow: -24px -9px 12px;
  }
}
.page__wrapper {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
    background-color: #f8f9fa;
    // .page__center {
    //     max-width: 1200px;
    //     margin: 0 auto;
    // }
}

.page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 88vh;
    position: relative;
    &:before {
        position: absolute;
        background: #e9e4e4;
        width: 1px;
        height: 100%;
        content: "";
        right: 25%;
        box-shadow: 0px 0 7px -2px;
    }
}
.list-group-item {
    padding: 8px 15px;
    &.active{
        z-index: 2;
        color: #fff;
        background-color: #6c5dd3;
        border-color: #5949c5;
    }
}
.page__wrapper-conten {
    padding: 30px;
    padding-bottom: 105px;
}

.page__stat {
    position: relative;
    padding: 50px 32px 40px;
    border-radius: 24px;
    background: #ffffff;
    box-shadow: 0px 0px 13px -9px;
    &:before {
        content: "";
        position: absolute;
        top: 21px;
        left: 32px;
        right: 32px;
        bottom: -22px;
        z-index: -1;
        background: #E3E6EC;
        opacity: 0.91;
        -webkit-filter: blur(86.985px);
        filter: blur(86.985px);
        border-radius: 24px;
    }
    .page__heading {
        h4 {
          font-weight: 500;
          text-transform: uppercase;
          font-size: 23px;
          color: #000;
        }  
    } 
}

.page__title {
  margin-bottom: 32px;
}

.products__price:not(:last-child) {
    margin-bottom: 10px;
}
.title {
    font-size: 16px;
    line-height: 1.1875;
    font-weight: 600;
}

/*****login******/
.login-form {
    display: flex;
    min-height: calc(100vh - 72px);
    justify-content: center; 
  .btn {
        border-radius: 28px;
        padding: 12px;
        font-size: 16px;
    }
   
}
/****************/

/*********/
.dropdown-menu {
    padding: 12px 8px 16px;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow; 
    background: #ffffff;
    border-radius: 10px;
    font-size: 14px;
    border: none;
    .dropdown-item {
      color: #a4a5a6;   
      font-weight: $font-weight-medium;
      &:active, &.active {
        color: #fff;
        text-decoration: none;
        background-color: $primary;
      }
      &:hover, &:focus  {
        border: 1px solid transparent !important;
      }
    }
}

.pagination {
    padding-left: 0;
    margin-top: 1rem;
  
    > {
      li {
        display: inline;
  
        &:first-child > {
          a, span {
            margin-left: 0;
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
          }
        }
      }
  
      .disabled > {
        a {
          color: #818a91;
          cursor: not-allowed;
          background-color: #fff;
          border-color: #ddd;
  
          &:focus, &:hover {
            color: #818a91;
            cursor: not-allowed;
            background-color: #fff;
            border-color: #ddd;
          }
        }
  
        span {
          color: #818a91;
          cursor: not-allowed;
          background-color: #fff;
          border-color: #ddd;
  
          &:focus, &:hover {
            color: #818a91;
            cursor: not-allowed;
            background-color: #fff;
            border-color: #ddd;
          }
        }
      }
  
      li > {
        a, span {
          position: relative;
          float: left;
          padding: 0.5rem 0.75rem;
          margin-left: -1px;
          line-height: 1.5;
          color: #6c5dd3;
          text-decoration: none;
          background-color: #fff;
          border: 1px solid #ddd;
        }
      }
  
      .active > {
        a {
          z-index: 2;
          color: #fff;
          cursor: default;
          background-color: #6c5dd3;
          border-color: #6c5dd3;
  
          &:focus, &:hover {
            z-index: 2;
            color: #fff;
            cursor: default;
            background-color: #6c5dd3;
            border-color: #6c5dd3;
          }
        }
  
        span {
          z-index: 2;
          color: #fff;
          cursor: default;
          background-color: #6c5dd3;
          border-color: #6c5dd3;
  
          &:focus, &:hover {
            z-index: 2;
            color: #fff;
            cursor: default;
            background-color: #6c5dd3;
            border-color: #6c5dd3;
          }
        }
      }
    }
  }
/********/

.weekDays-selector input {
  display: none!important;
}

.weekDays-selector input[type=checkbox] + label {
  display: inline-block;
  height: 40px;
  width: 50px;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc !important;
}

.weekDays-selector input[type=checkbox]:checked + label {
  background: #6d5bfc;
  color: #ffffff;
}
.form-check-label {
  &:not(:first-child) {
      margin-left: -1px;
  }
}  
 

.dashboard {
  ul {
    &.nav {
      .nav-item {
        .rs-picker-toggle {
          border: none;
        }
        .nav-link {
          color: $black;
          font-weight: $font-weight-medium;
          &.active {
            color: $primary;
          }
        }
      }  
    }
  }
  .card {
    box-shadow: $box-shadow;
    border: none;
    border-radius: 10px;
  }
  .chartbar4 {
    .position-absolute {
      bottom: 32px;
      left: 52px;
      text-align: center;
    }  
    tspan {
      font-size: 14px;
    }
  }
  .progress {
    height: 10px;
  }
  .apexcharts-legend {
    display: none !important;
  }
  .apexcharts-toolbar{
    display: none !important;
  }
  .apexcharts-xaxis-texts-g {
    display: none !important;
  }
  .apexcharts-grid{
    display: none !important;
  }
  .apexcharts-xaxis {
    display: none !important;
  }
}
.code-script {
  overflow: initial;
  white-space: break-spaces;
}
.icon__mg.show {display: flex !important; }
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{line-height: 1.2 !important;}

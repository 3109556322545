
    .search {
        grid-column: 3/4;
        grid-row: 2/3;
        position: relative;
        display: flex;
        align-items: center;
        .search__input { width: 100%;
        height: 54px;
        border: 1px solid #e1e1e1;
        border-radius: 50px;
        font-size: 16px;
        padding-left: 51px;
        background-color: #ffffff;
        font-family: inherit;
        color: var(--greyDark);
        }
        .search__input {
            &::-moz-placeholder {
                color: var(--greyLight-3);
            }
            &:-ms-input-placeholder {
                color: var(--greyLight-3);
            }
            &::placeholder {
                color: var(--greyLight-3);
            }
            &:focus {
                outline: none;
                box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2), -0.2rem -0.2rem 0.5rem var(--white);
                + {
                    .search__icon {
                        color: var(--primary);
                    }
                }
            }
        }
        .search__icon {
            height: 18px;
            position: absolute;
            font-size: 19px;
            padding: 0 1rem;
            display: flex;
            color: var(--greyDark);
            transition: 0.3s ease;
        }
    }    
.rightsidebar {
    padding: 30px;
    .list-group-item {
        color: $primary;

        &.active{
            z-index: 2;
            color: #fff;
            background-color:$primary;
            border-color: $primary;
        }
    }
    .noti-btn {
        position: relative;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        font-size: 18px;
        -webkit-transition: all .25s;
        -o-transition: all .25s;
        transition: all .25s;    
        background: #6C5DD3;
        box-shadow: 0 5px 10px #e3e6ec99;
   }
   .profile-btn {
       position: relative;
       width: 48px;
       height: 48px;
       border-radius: 50%;
       font-size: 18px;
       -webkit-transition: all .25s;
       -o-transition: all .25s;
       transition: all .25s;    
       background: #6C5DD3;
       box-shadow: 0 5px 10px #e3e6ec99;
  }
  .logout-btn {
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      font-size: 18px;
      -webkit-transition: all .25s;
      -o-transition: all .25s;
      transition: all .25s;    
      background: #6C5DD3;
      box-shadow: 0 5px 10px #e3e6ec99;
 }
}
